<template>
  <div>
    <div class="page-header">
      <img class="bg" src="@/assets/page-header-bg.png" alt="" />
      <span class="text">新增资产</span>
    </div>

    <a-form
      :form="form"
      @submit="handleSubmit"
      :colon="false"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 16 }"
    >
      <a-row :gutter="8">
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="资产名称">
            <a-input
              v-decorator="[
                'name',
                {
                  rules: [{ required: true, message: '请输入' }],
                },
              ]"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="品牌">
            <a-input v-decorator="['brand', {}]" />
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="规格型号">
            <a-input v-decorator="['specification', {}]" />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="单位">
            <a-input v-decorator="['unit', {}]" />
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="单价(元)">
            <a-input-number
              style="width: 100%"
              v-decorator="[
                'price',
                {
                  rules: [{ required: true, message: '请输入' }],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="数量">
            <a-input-number style="width: 100%" v-decorator="['number', {}]" />
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="保管员">
            <a-select
              v-decorator="[
                'chargerId',
                {
                  rules: [
                    {
                      required: true,
                      message: '请选择',
                    },
                  ],
                },
              ]"
            >
              <a-select-option
                v-for="item in userList"
                :key="item.id"
                :value="item.id"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>

      <div class="center">
        <a-space>
          <a-button @click="cancel">关闭</a-button>
          <a-button type="primary" html-type="submit" :loading="loading"
            >提交</a-button
          >
        </a-space>
      </div>
    </a-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { add } from "../api/list";
export default {
  data() {
    return {
      form: this.$form.createForm(this),
      loading: false,
    };
  },

  computed: {
    ...mapState("userSelector", ["userList"]),
  },

  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true;
          add({
            ...values,
            charger: this.userList.find((item) => item.id === values.chargerId)
              .name,
          })
            .then(() => {
              this.cancel();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },

    cancel() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.page-header {
  position: relative;
  margin-bottom: 1vh;
  .bg {
    width: 240px;
    display: block;
  }

  .text {
    position: absolute;
    top: 10%;
    left: 0;
    bottom: 0;
  }
}
</style>